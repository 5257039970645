<template>
  <b-container>
    <b-row class="mb-3" align-v="stretch">
      <b-col>
        <SelectCard @select-card-clicked="changeState" title="Pakete" bgVariant="green" state="packet-select">
          <template #card-body>
            <b-icon-box-seam font-scale="2"/>
          </template>
        </SelectCard>
      </b-col>
      <b-col>
        <SelectCard @select-card-clicked="changeState" title="Kursweise" bgVariant="teal" state="course-select">
          <template #card-body>
            <b-icon-collection-play font-scale="2"/>
          </template>
        </SelectCard>
      </b-col>
      <b-col>
        <SelectCard @select-card-clicked="changeState" title="Imaging" bgVariant="orange-dark" state="image">
          <template #card-body>
            <b-icon-hdd font-scale="2"/>
          </template>
        </SelectCard>
      </b-col>
    </b-row>
    <b-row class="mb-3" align-v="stretch">
      <b-col>
        <SelectCard @select-card-clicked="changeState" title="Befehle" bgVariant="red-dark" state="command-select">
          <template #card-body>
            <b-icon-terminal font-scale="2"/>
          </template>
        </SelectCard>
      </b-col>
      <b-col>
        <SelectCard v-if="!groupList" @select-card-clicked="changeState" title="Hostname ändern"
                    bgVariant="light-brown" state="hostname">
          <template #card-body>
            <b-icon-input-cursor-text font-scale="2"/>
          </template>
        </SelectCard>
        <SelectCard v-else @select-card-clicked="changeState" title="Shutdown ändern"
                    bgVariant="light-brown" state="shutdown">
          <template #card-body>
            <b-icon-input-cursor-text font-scale="2"/>
          </template>
        </SelectCard>
      </b-col>
      <b-col>
        <SelectCard @select-card-clicked="changeState" title="Sync" bgVariant="light-blue" state="sync-select">
          <template #card-body>
            <b-icon-cloud-download font-scale="2"/>
          </template>
        </SelectCard>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SelectCard from "@/components/lists/computerList/tasks/utility/SelectCard.vue";

export default {
  name: "Start",
  props: ['groupList'],
  components: {
    SelectCard
  },
  methods: {
    changeState(data) {
      this.$emit('change-state', data)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
