<template>
  <b-modal :id="'createTaskModal-' + pcUuid" centered size="xl" @hidden="reset">
    <template #modal-header="{ close }">
      <TaskModalHeader :close="close" :pcHostname="pcHostname" :pcCount="pcCount" :groupList="groupList"
                       type="createTaskModal" :state="currentState.akt" :description="currentState.description"
                       @change-state="changeState"/>
    </template>
    <template #default>
      <Start v-if="currentState.akt === 'start'" :groupList="groupList" @change-state="changeState"/>
      <Select v-if="currentState.akt.includes('select')" :state="currentState" :packetsInstalled="packetsInstalled"
              @add-option-list="addOptionList" @change-state="changeState"/>
      <Shutdown v-if="currentState.akt === 'shutdown'" :state="currentState"
                @add-new-task="addTask" @change-state="changeState"/>
      <Imaging v-if="currentState.akt === 'image'" :state="currentState" :pcId="pcId" :pcUuid="pcUuid"
               :groupList="groupList" :imageAktuell="imageAktuell" :imageNext="nextImage" @change-image="changeImage"
               @add-new-task="addTask" @change-state="changeState"/>
      <Hostname v-if="currentState.akt === 'hostname'" :state="currentState"  :pcId="pcId" :pcUuid="pcUuid"
                :pcHostname="pcHostname" @add-new-task="addTask" @change-state="changeState"/>
      <Option v-if="currentState.akt.includes('option')" :state="currentState" :optionList="optionList" :pcId="pcId"
              @add-new-task="addTask" @remove-task="removeOptionList"/>
      <Finish v-if="currentState.akt === 'finish'" :state="currentState" :taskList="taskList" :groupList="groupList"
              :pcHostname="pcHostname" :pcCount="pcCount" :imageAktuell="imageAktuell"
              @delete-task="deleteTask" @group-tasks="groupTasks" @tasks-added="taskAdded" @change-state="changeState"/>
    </template>
    <template #modal-footer>
      <div class="mx-auto">
        <b-icon-arrow-left-circle v-if="currentState.prev !== ''" font-scale="2" class="mr-2" @click="stepBack"/>
        <b-icon-arrow-right-circle v-if="currentState.next !== ''" font-scale="2" @click="stepAhead"/>
      </div>
    </template>
  </b-modal>
</template>

<script>
import _ from "lodash";
import {toastInfo} from "@/modules/status";
import Start from "@/components/lists/computerList/tasks/createTaskModal/Start.vue";
import Finish from "@/components/lists/computerList/tasks/createTaskModal/Finish.vue";
import Select from "@/components/lists/computerList/tasks/createTaskModal/Select.vue";
import Option from "@/components/lists/computerList/tasks/createTaskModal/Option.vue";
import Hostname from "@/components/lists/computerList/tasks/createTaskModal/Hostname.vue";
import Imaging from "@/components/lists/computerList/tasks/createTaskModal/Imaging.vue";
import Shutdown from "@/components/lists/computerList/tasks/createTaskModal/Shutdown.vue";
import TaskModalHeader from "@/components/lists/computerList/tasks/utility/TaskModalHeader.vue";

export default {
  name: 'CreateTaskModal',
  props: ['pcId', 'pcUuid', 'imageAktuell', 'imageNext', 'pcHostname', 'groupList', 'pcCount', 'packetsInstalled'],
  components: {
    Shutdown,
    Imaging,
    Hostname,
    Option,
    Select,
    Finish,
    Start,
    TaskModalHeader
  },
  data() {
    return {
      currentState: {
        akt: "start",
        prev: "",
        next: "",
        description: "Aktion - Erstellen"
      },
      states: [
        {
          akt: "start",
          prev: "",
          next: "",
          description: "Aktion - Erstellen"
        },
        {
          akt: "packet-select",
          prev: "start",
          next: "packet-option",
          type: "packet",
          description: "Pakete - Auswahl"
        },
        {
          akt: "packet-option",
          prev: "packet-select",
          next: "finish",
          type: "packet",
          description: "Pakete - Optionen"
        },
        {
          akt: "course-select",
          prev: "start",
          next: "course-option",
          type: "course",
          description: "Kursweise - Auswahl"
        },
        {
          akt: "course-option",
          prev: "course-select",
          next: "finish",
          type: "course",
          description: "Kursweise - Optionen"
        },
        {
          akt: "command-select",
          prev: "start",
          next: "command-option",
          type: "command",
          description: "Befehle - Auswahl"
        },
        {
          akt: "command-option",
          prev: "command-select",
          next: "finish",
          type: "command",
          description: "Befehle - Optionen"
        },
        {
          akt: "sync-select",
          prev: "start",
          next: "sync-option",
          type: "sync",
          description: "Sync - Auswahl"
        },
        {
          akt: "sync-option",
          prev: "sync-select",
          next: "finish",
          type: "sync",
          description: "Sync - Optionen"
        },
        {
          akt: "image",
          prev: "start",
          next: "finish",
          description: "Image - Optionen"
        },
        {
          akt: "hostname",
          prev: "start",
          next: "finish",
          description: "Hostname - Optionen"
        },
        {
          akt: "shutdown",
          prev: "start",
          next: "finish",
          description: "Shutdown - Optionen"
        },
        {
          akt: "finish",
          prev: "",
          next: "",
          description: "Übersicht"
        }
      ],
      optionList: [],
      taskList: [],
    }
  },
  methods: {
    addTask(data) {
      this.taskList.push(data)
      toastInfo(this, 'Erfolgreich der Übersicht hinzugefügt!')
    },
    deleteTask(data) {
      this.taskList = this.taskList.filter(e => e !== data);
      toastInfo(this, 'Erfolgreich von der Übersicht entfernt!')
    },
    addOptionList(data) {
      this.optionList = data;
    },
    removeOptionList(data) {
      this.optionList = this.optionList.filter(e => e !== data);
      if(this.optionList.length === 0) {
        this.changeState('finish')
      }
    },
    changeImage(data) {
      this.nextImage = data;
      this.$emit('refresh');
    },
    changeState(data) {
      this.currentState = _.find(this.states, (state) => {
        if(data === state.akt) { return true }
      })
    },
    taskAdded(data) {
      if(!data.failed) {
        this.$emit('refresh');
        this.$bvModal.hide('createTaskModal-' + this.pcUuid);
      }
    },
    groupTasks(data) {
      this.$emit('group-tasks', data)
    },
    stepAhead() {
      this.currentState = _.find(this.states, (state) => {
        if(this.currentState.next === state.akt) { return true }
      })
    },
    stepBack() {
      this.currentState = _.find(this.states, (state) => {
        if(this.currentState.prev === state.akt) { return true }
      })
    },
    reset() {
      this.currentState =  {
        akt: "start",
            prev: "",
            next: "",
            description: "Aktion - Erstellen"
      }
      this.optionList = []
      this.taskList = []
    }
  },
  computed: {
    nextImage() {
      if(this.imageNext) {
       return this.imageNext
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/styles/Modal';
</style>
