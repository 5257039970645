<template>
  <b-container>
    <b-row>
      <b-col class="mr-auto pr-0">
        <label class="mt-2">Hostname:</label>
      </b-col>
      <b-col class="mr-auto" cols="9">
        <b-form-input v-model="hostname" type="text" placeholder="zB. MA-HOME-001" required/>
      </b-col>
      <b-col cols="auto" class="ml-auto pr-0 mr-3">
        <b-button variant="primary" @click="validateNewHostname" :disabled="disabledButton">
          <b-spinner v-show="loadingValidateHostname" small/>
          Bestätigen
        </b-button>
      </b-col>
    </b-row>
    <TimeOption @new-time="newTime" @new-date="newDate" @date-time-check="datetimeCheck"/>
  </b-container>
</template>

<script>
import dayjs from "dayjs";
import {toastDanger} from "@/modules/status";
import {getRequest, putRequest} from "@/modules/requests";
import TimeOption from "@/components/lists/computerList/tasks/utility/dateTime/TimeOption.vue";

export default {
  name: "Hostname",
  props: ['pcHostname', 'pcUuid', 'pcId'],
  components: {
    TimeOption
  },
  data() {
    return {
      hostname: this.pcHostname,
      loadingValidateHostname: false,
      hostnameId: null,
      disabledButton: false,
      time: dayjs().format('HH:mm'),
      date: dayjs().format('DD.MM.YYYY'),
    }
  },
  methods: {
    emitTask() {
      let data = {
        'pc_id': this.pcId,
        'command_id': this.hostnameId,
        'sync_id': null,
        'command_parameter': this.hostname,
        'time_execute_scheduled': this.date + ' ' + this.time,
        'displayname': 'Hostname'
      }
      this.$emit('add-new-task', data)
      this.$emit('change-state', 'finish')
    },
    newTime(data) {
      this.time = data
    },
    newDate(data) {
      this.date = data
    },
    datetimeCheck(data) {
      this.disabledButton = data
    },
    async validateNewHostname() {
      this.loadingValidateHostname = true;
      let data = {
        pc_uuid: this.pcUuid,
        hostname: this.hostname
      }
      await putRequest('tasks/hostname-check', data, this, null)
          .then(() => {
            this.loadingValidateHostname = false;
            this.emitTask()
          })
          .catch((error) => {
            this.loadingValidateHostname = false;
            toastDanger(this, error.response.data.errors.hostname[0]);
          })
    },
    async getHostnameId() {
      await getRequest('tasks/hostname', null, this)
          .then((response) => {
            this.hostnameId = response.data.command_id
          })
    },
  },
  mounted() {
    this.getHostnameId()
  }
}
</script>

<style lang="scss" scoped>
</style>
