<template>
  <b-container>
    <b-row>
      <b-col class="mr-auto pr-0">
        <label class="mt-2">Schaltzeit:</label>
      </b-col>
      <b-col class="mr-auto" cols="9">
        <b-form-select v-model="shutdownTime" required class="w-25">
          <b-form-select-option value="16:45">16:45</b-form-select-option>
          <b-form-select-option value="17:20">17:20</b-form-select-option>
          <b-form-select-option value="23:59">23:59</b-form-select-option>
        </b-form-select>
        <b-form-group label="Ausschalten?" class="mt-3 mb-0">
          <b-form-radio v-model="shutdown" :value="null">Keine Änderung</b-form-radio>
          <b-form-radio v-model="shutdown" :value="true">Ja</b-form-radio>
          <b-form-radio v-model="shutdown" :value="false">Nein</b-form-radio>
        </b-form-group>
      </b-col>
      <b-col cols="auto" class="ml-auto pr-0 mr-3">
        <b-button variant="primary" @click="emitTask">
          Bestätigen
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Shutdown",
  props: ['pcId'],
  data() {
    return {
      shutdown: null,
      shutdownTime: '16:45',
      time: dayjs().format('HH:mm'),
      date: dayjs().format('DD.MM.YYYY'),
    }
  },
  methods: {
    emitTask() {
      let data = {
        'pc_id': this.pcId,
        'command_id': null,
        'sync_id': null,
        'command_parameter': this.shutdownTime,
        'time_execute_scheduled': this.date + ' ' + this.time,
        'type': 'shutdown',
        'shutdown': this.shutdown,
        'shutdown_text': 'Schaltzeit',
        'displayname': 'Schaltzeitänderung'
      }
      this.$emit('add-new-task', data)
      this.$emit('change-state', 'finish')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>