<template>
  <div>
    <label>Ausführen am (optional):</label>
    <div class="w-25">
      <Datepicker @datepicker-changed="datepickerChange" @date-changed="dateChange" @datetime-invalid="datetimeCheck"/>
      <Timepicker class="mt-2" @time-changed="timeChange" @datetime-invalid="datetimeCheck"/>
    </div>
  </div>
</template>

<script>
import Datepicker from "@/components/lists/computerList/tasks/utility/dateTime/Datepicker.vue";
import Timepicker from "@/components/lists/computerList/tasks/utility/dateTime/Timepicker.vue";

export default {
  name: "TimeOption",
  components: {
    Datepicker,
    Timepicker
  },
  methods: {
    timeChange(data) {
      this.$emit('new-time', data)
    },
    dateChange(data) {
      this.$emit('new-date', data)
    },
    datepickerChange(data) {
      this.$emit('new-date', data)
    },
    datetimeCheck(data) {
      this.$emit('date-time-check', data.value)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
