<template>
  <b-container>
    <b-row v-if="!groupList">
      <b-col class="mr-auto pr-0">
        <div>Aktuelles Image: <span class="text-bold">{{ imageAktuell }}</span></div>
      </b-col>
      <b-col class="mr-auto pl-0">
        <div>Nächstes Image:
          <b-form-select size="sm" class="mb-2 mt-1 w-75" v-model="imageSelect.selected" :options="imageSelect.options"
                         @change="changeImage"/>
        </div>
      </b-col>
      <b-col class="ml-auto pr-0 mr-3" cols="auto">
        <b-button variant="primary" :disabled="disabledButton" @click="emitTask">
          Bestätigen
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <TimeOption @new-time="newTime" @new-date="newDate" @date-time-check="datetimeCheck"/>
      </b-col>
      <b-col v-if="groupList" class="ml-auto pr-0 mr-3" cols="auto">
        <b-button variant="primary" :disabled="disabledButton" @click="emitTask">
          Bestätigen
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import {getRequest, putRequest} from "@/modules/requests";
import TimeOption from "@/components/lists/computerList/tasks/utility/dateTime/TimeOption.vue";

export default {
  name: "Imaging",
  props: ['imageAktuell', 'imageNext', 'pcId', 'pcUuid', 'groupList'],
  components: {
    TimeOption
  },
  data() {
    return {
      disabledButton: false,
      imageId: null,
      imageSelect: {
        selected: null,
        options: null
      },
      time: dayjs().format('HH:mm'),
      date: dayjs().format('DD.MM.YYYY'),
    }
  },
  methods: {
    emitTask() {
      let data = {
        'pc_id': this.pcId,
        'command_id': this.imageId,
        'sync_id': null,
        'command_parameter': this.imageNext,
        'time_execute_scheduled': this.date + ' ' + this.time,
        'displayname': 'Imaging'
      }
      this.$emit('add-new-task', data)
      this.$emit('change-state', 'finish')
    },
    newTime(data) {
      this.time = data
    },
    newDate(data) {
      this.date = data
    },
    datetimeCheck(data) {
      this.disabledButton = data
    },
    changeState(data) {
      this.$emit('change-state', data)
    },
    async getImageId() {
      await getRequest('tasks/image', null, this)
          .then((response) => {
            this.imageId = response.data.command_id
          })
    },
    async getImages() {
      await getRequest('/images', null, this)
          .then((response) => {
            this.imageSelect.options = []
            response.data.forEach(element => {
              this.imageSelect.options.push({value: element.id, text: element.displayname})
            })
            let imageNext = _.find(this.imageSelect.options, (option) => {
              if(option.text === this.imageNext) { return true }
            })
            this.imageSelect.selected = imageNext.value
          })
    },
    async changeImage() {
      await putRequest('/image-next/' + this.pcUuid, { image_next_id: this.imageSelect.selected }, this, 'Das nächste Image wurde erfolgreich geändert!')
          .then(() => {
            let imageNext = _.find(this.imageSelect.options, (option) => {
              if(this.imageSelect.selected === option.value) { return true }
            })
            this.$emit('change-image', imageNext.text)
          })
    }
  },
  mounted() {
    this.getImageId()
    if(!this.groupList) { this.getImages() }
  }
}
</script>

<style lang="scss" scoped>
</style>
