<template>
  <b-card class="text-center hover" text-variant="white" :bg-variant=bgVariant :body-class=bodyClass @click="emitClicked">
    <b-card-title>
      <slot name="card-title">
        <h4 class="card-title">{{ title }}</h4>
      </slot>
    </b-card-title>
    <b-card-body>
      <slot name="card-body"/>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "SelectCard",
  props: ['title', 'state', 'bgVariant', 'bodyClass'],
  methods: {
    emitClicked() {
      this.$emit('select-card-clicked', this.state)
    }
  }
}
</script>

<style lang="scss" scoped>
.hover {
  transition: .2s;
  cursor: pointer;
}

.hover:hover {
  transform: scale(1.025);
}
</style>
