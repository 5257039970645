<template>
  <b-container>
    <b-row>
      <b-col cols="3" class="mr-3 pr-0">
        <b-form-input v-model="search" debounce="500" :placeholder="searchPlaceholder" class="mb-2"/>
      </b-col>
      <b-col cols="3" class="mt-1 mr-auto pr-0" v-if="state.type === 'packet'">
        <b-form-checkbox v-model="softwareInstalled">
          <b-badge id="tt-installedPackets" variant="primary">Installierte Pakete</b-badge>
        </b-form-checkbox>
        <b-tooltip target="tt-installedPackets" triggers="hover">Nur installierte Pakete anzeigen</b-tooltip>
      </b-col>
      <b-col cols="auto" class="ml-auto">
        <b-form-select v-if="state.type === 'course'" v-model="courseCycleSelect.selected"
                       :options="courseCycleSelect.options" @change="getCourses"/>
        <span v-else-if="state.type === 'packet'" class="text-secondary small">
          Durchschnittliche Installationszeit in HH:mm:ss
        </span>
      </b-col>
    </b-row>
    <b-list-group v-if="!loadingItemList && !softwareInstalled" class="list-size">
      <template v-for="item in filterList">
        <b-list-group-item v-if="state.type === 'packet'" class="list-font-size" button :key="item.id"
                           @click="addToListMulti(item)" :variant="item.active ? 'success' : ''"
                           :active.sync="item.active">
          <b-row>
            <b-col>
              <div>{{ item.displayname }}</div>
            </b-col>
            <b-col class="text-center text-info small">
              <span v-if="packetInstalled(item)">Paket ist bereits installiert!</span>
            </b-col>
            <b-col class="text-right text-secondary small">
              <span v-if="item.estimate_install_time !== null">{{ item.estimate_install_time }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-else-if="state.type === 'sync'" class="list-font-size" button :key="item.id"
                           @click="addToListMulti(item)" :active.sync="item.active"
                           :variant="item.active ? 'success' : ''">
          <b-row>
            <b-col>
              <div>{{ item.displayname }}</div>
            </b-col>
            <b-col class="text-right text-secondary small">
              <span>{{ item.file_name }} | {{ item.size_packed | float_de }} GB</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-else class="list-font-size" button :key="item.id" @click="addToList(item)">
          {{ item.displayname }}
        </b-list-group-item>
      </template>
    </b-list-group>
    <b-list-group v-else-if="!loadingItemList && softwareInstalled" class="list-size">
      <template v-for="item in softwareInstalledList">
        <b-list-group-item v-if="state.type === 'packet'" class="list-font-size" button :key="item.id"
                           @click="addToListMulti(item)" :variant="item.active ? 'success' : ''"
                           :active.sync="item.active">
          <b-row>
            <b-col>
              <div>{{ item.displayname }}</div>
            </b-col>
            <b-col class="text-center text-info small">
              <span v-if="packetInstalled(item)">Paket ist bereits installiert!</span>
            </b-col>
            <b-col class="text-right text-secondary small">
              <span v-if="item.estimate_install_time !== null">{{ item.estimate_install_time }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-else-if="state.type === 'sync'" class="list-font-size" button :key="item.id"
                           @click="addToListMulti(item)" :active.sync="item.active"
                           :variant="item.active ? 'success' : ''">
          <b-row>
            <b-col>
              <div>{{ item.displayname }}</div>
            </b-col>
            <b-col class="text-right text-secondary small">
              <span>{{ item.file_name }} | {{ item.size_packed | float_de }} GB</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-else class="list-font-size" button :key="item.id" @click="addToList(item)">
          {{ item.displayname }}
        </b-list-group-item>
      </template>
    </b-list-group>
    <div v-else class="d-flex justify-content-center">
      <b-spinner class="mt-3" :variant="spinnerVariant" style="width: 3rem; height: 3rem;"/>
    </div>
  </b-container>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";
import {getRequest} from "@/modules/requests";

export default {
  name: "Select",
  props: ['state', 'packetsInstalled'],
  data() {
    return {
      search: '',
      softwareInstalled: false,
      loadingItemList: false,
      itemList: [],
      taskList: [],
      installedLicenses: [],
      courseCycleSelect: {
        selected: null,
        options: []
      }
    }
  },
  methods: {
    addToListMulti(item) {
      if(!item.active) {
        item.active = true;
        this.taskList.push(item);
      } else {
        item.active = false;
        this.taskList = this.taskList.filter(e => e !== item);
      }
    },
    addToList(item) {
      this.taskList.push(item)
      if(this.state.type === 'command') {
        this.changeState('command-option')
      } else if(this.state.type === 'course') {
        this.changeState('course-option')
      }
    },
    packetInstalled(item) {
      if(this.installedLicenses.length > 0) {
        return _.find(this.installedLicenses, (entry) => {
          if(item.displayname.toLowerCase() === entry.toLowerCase()) {
            return true
          }
        }) !== undefined
      } else {
        return false
      }
    },
    changeState(data) {
      this.$emit('change-state', data)
    },
    setInstalledLicenses() {
      _.forEach(this.packetsInstalled, (disk) => {
        _.forEach(disk.licenses, (license) => {
          this.installedLicenses.push(license.license.displayname)
        })
      })
    },
    async loadItemList() {
      switch(this.state.type) {
        case 'command':
          await this.getCommandsId()
          break;
        case 'sync':
          await this.getSyncsId()
          break;
        case 'course':
          await this.getCourseCycles()
          await this.getCourses()
          break;
        case 'packet':
          await this.getPacketsId()
          break;
      }
    },
    async getPacketsId() {
      this.loadingItemList = true;
      await getRequest('tasks/packets', null, this)
          .then((response) => {
            response.data.forEach(element => {
              if(this.itemList.some(e => e.displayname === element.displayname)) {
                let i = this.itemList.findIndex(e => e.displayname === element.displayname)
                this.itemList[i].commands.push({type: element.type, id: element.command_id})
              } else {
                this.itemList.push({
                  displayname: element.displayname,
                  active: false,
                  switchbox: true,
                  commands: [{type: element.type, id: element.command_id}],
                  estimate_install_time: element.estimate_install_time
                })
              }
            })
            this.itemList.sort((a, b) => (a.displayname > b.displayname) ? 1 : -1);
            this.loadingItemList = false;
          })
          .catch(() => {
            this.loadingItemList = false;
          })
    },
    async getCommandsId() {
      this.loadingItemList = true;
      await getRequest('tasks/commands', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.itemList.push({
                displayname: element.displayname,
                id: element.command_id
              })
            })
            this.itemList.sort((a, b) => (a.displayname > b.displayname) ? 1 : -1);
            this.loadingItemList = false;
          })
          .catch(() => {
            this.loadingItemList = false;
          })
    },
    async getSyncsId() {
      this.loadingItemList = true;
      await getRequest('tasks/syncs', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.itemList.push({
                displayname: element.displayname,
                id: element.sync_id,
                active: false,
                file_name: element.file_name,
                size_packed: element.size_packed / 1000
              })
            })
            this.itemList.sort((a, b) => (a.displayname > b.displayname) ? 1 : -1);
            this.loadingItemList = false;
          })
          .catch(() => {
            this.loadingItemList = false;
          })
    },
    async getCourses() {
      this.loadingItemList = true;
      this.itemList = [];
      await getRequest('tasks/checklist/' + this.courseCycleSelect.selected, null, this, 'Beim Laden der Checkliste ist ein Fehler aufgetreten!')
          .then((response) => {
            response.data.forEach(element => {
              this.itemList.push({
                displayname: element.displayname,
                id: element.id,
                commands: element.commands
              })
            })
            this.itemList.sort((a, b) => (a.displayname > b.displayname) ? 1 : -1);
            this.loadingItemList = false;
          })
          .catch(() => {
            this.loadingItemList = false;
          })
    },
    async getCourseCycles() {
      let courseCycleId = null
      await getRequest('course-cycles', null, this)
          .then((response) => {
            let currentIndex = _.findIndex(response.data, (cycle) => {
              if(cycle.current) {
                return true
              }
            })
            for(let i = currentIndex - 4; i < currentIndex + 2; i++) {
              let element = response.data[i]
              let text = element.time_start + ' - ' + element.time_end
              if(element.current) {
                courseCycleId = element.id
                text += ' aktuell'
              } else if(element.future) {
                text += ' forecast'
              }
              this.courseCycleSelect.options.push({
                value: element.id,
                text: text
              })
            }
          })
      this.courseCycleSelect.selected = courseCycleId;
    },
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    spinnerVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'white'
    },
    filterList() {
      return this.itemList.filter(item => {
        return item.displayname.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    searchPlaceholder() {
      if(this.state.type === 'packet') {
        return 'Suche nach Paket...'
      }
      return 'Suche nach Kurs...'
    },
    softwareInstalledList() {
      return this.itemList.filter(item => {
        return item.displayname.toLowerCase().includes(this.search.toLowerCase()) && this.packetInstalled(item)
      })
    }
  },
  mounted() {
    this.loadItemList()
    if(this.state.type === 'packet') {
      this.setInstalledLicenses()
    }
  },
  beforeDestroy() {
    this.$emit('add-option-list', this.taskList)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/styles/Modal';
</style>
